import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { productFruits } from 'product-fruits';
import { UserService } from './core/user/user.service';
import { Subject, takeUntil } from 'rxjs';
import { User } from './core/user/user.types';
import { AppConfigService } from './services/app.config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  /**
   * Constructor
   */
  constructor(
    private _userService: UserService,
    private _changeDetectorRef: ChangeDetectorRef,
    private appConfigService: AppConfigService
  ) {}
  ngOnInit(): void {
    this.loadPf();
  }

  loadPf() {
    this._userService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {
        productFruits.init(
          this.appConfigService.getAppSettings().productFruitWorkSpaceCode,
          'en',
          { username: user?.email },
          { disableLocationChangeDetection: false }
        );

        productFruits.safeExec($pf => {
          console.log($pf);
        });
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
